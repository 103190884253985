<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="auto">
          <v-btn class="label" text color="transparent">
            <div class="font-weight-regular secondary--text text-capitalize">
              {{ $t("page_faqs_create_title") }}
            </div>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="auto">
          <v-btn
            small
            class="text-capitalize font-weight-regular"
            text
            @click="$router.push({ name: 'Faq' })"
          >
            <v-icon color="secondary" left>$goBack</v-icon>
            {{ $t("go_back") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider></v-divider>

    <validation-observer ref="observer">
      <form @submit.prevent="submit">
        <v-card-text class="custom-card-text">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-row align="center">
                  <v-col
                    cols="12"
                    md="1"
                    class="text-md-right pb-0 pb-md-4 category-text"
                  >
                    {{ $t("category") }}
                  </v-col>

                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Category"
                      rules="required"
                    >
                      <v-select
                        outlined
                        flat
                        dense
                        item-value="id"
                        item-text="name"
                        :items="categories"
                        v-model="selectedCategory"
                        return-object
                        :placeholder="$t('select_category')"
                        :error-messages="errors"
                      >
                        <template v-slot:selection="{ item }">
                          {{ item.name }}
                        </template>
                      </v-select>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-row align="center">
                      <v-col
                        v-for="field in fields"
                        :key="field.name"
                        cols="12"
                      >
                        <v-row align="center">
                          <v-col cols="12" md="1" class="text-right label-text">
                            {{ field.label }}
                          </v-col>

                          <template v-if="field.type === 'text'">
                            <v-col cols="12" md="6">
                              <validation-provider
                                v-slot="{ errors }"
                                :name="field.name"
                                :rules="field.rules"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  flat
                                  :type="field.type"
                                  v-model="field.value"
                                  :placeholder="field.placeholder"
                                  :error-messages="errors"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                          </template>

                          <template v-if="field.type === 'textarea'">
                            <v-col cols="12" md="6">
                              <template v-if="field.name == 'answer'">
                                <validation-provider
                                  v-slot="{ errors }"
                                  :name="field.name"
                                >
                                  <PostEditor
                                    :data.sync="answer"
                                    :label="field.label"
                                    :error-messages="errors"
                                  ></PostEditor>
                                  <div
                                    class="error--text m-0"
                                    v-if="answerErrors"
                                  >
                                    {{ answerErrors }}
                                  </div>
                                </validation-provider>
                              </template>
                              <template v-else>
                                <validation-provider
                                  v-slot="{ errors }"
                                  :name="field.name"
                                  :rules="field.rules"
                                >
                                  <v-textarea
                                    solo
                                    v-model="field.value"
                                    :placeholder="field.placeholder"
                                    :error-messages="errors"
                                  ></v-textarea>
                                </validation-provider>
                              </template>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col cols="12" md="1" class="text-right label-text">
                        {{ $t("status") }}
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-switch
                          inset
                          color="primary"
                          v-model="status"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" offset-md="6">
                    <v-btn
                      type="submit"
                      :disabled="formStatus"
                      :loading="formStatus"
                      color="primary"
                      class="text-capitalize font-weight-regular"
                      depressed
                      small
                    >
                      {{ $t("button_create") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </form>
    </validation-observer>
  </v-card>
</template>

<script>
import PostEditor from "@/components/admin/partials/PostEditor/PostEditor";

export default {
  name: "index",
  components: { PostEditor },
  computed: {
    categories() {
      return this.$store.getters.faqCategories;
    }
  },
  created() {
    this.$store.dispatch("FAQ_GET_CATEGORY_LIST");
  },
  data() {
    return {
      valid: false,
      status: true,
      fields: [
        {
          label: this.$t("page_faqs_field_question"),
          placeholder: this.$t("page_faqs_create_field_question_placeholder"),
          name: "question",
          type: "textarea",
          value: "",
          rules: "required"
        },
        {
          label: this.$t("page_faqs_field_answer"),
          placeholder: this.$t("page_faqs_create_field_answer_placeholder"),
          name: "answer",
          type: "textarea",
          value: "",
          rules: "required"
        },
        {
          label: this.$t("page_faqs_create_field_display_order"),
          name: "order",
          type: "text",
          placeholder: this.$t(
            "page_faqs_create_field_display_order_placeholder"
          ),
          value: "",
          rules: "required"
        }
      ],
      selectedCategory: null,
      formStatus: false,
      answerErrors: "",
      answer: ""
    };
  },
  methods: {
    async submit() {
      this.formStatus = true;
      if (this.answer == "") {
        this.answerErrors = this.$t("field_required_message");
      } else {
        this.answerErrors = "";
      }
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.formStatus = false;
          return;
        }

        let data = {};

        this.fields.forEach(field => {
          data[field.name] = field.value;
        });

        data.category_id = this.selectedCategory.id;
        data.answer = this.answer;
        data.status = this.status === true ? 1 : 0;

        this.$store
          .dispatch("FAQ_ADD_NEW", data)
          .then(result => {
            if (result.status === 200) {
              this.$refs.observer.reset();
              this.$router.push({ name: "Faq" });
            }
            this.formStatus = false;
          })
          .finally(() => (this.formStatus = false));
      });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
